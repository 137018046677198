import { Button, ButtonProps } from "@mui/material";
import { useDispatch } from "react-redux";
import { actionSetRightsDialog } from "../../../../store/dialog/reducer";

export function RightsButton(props: { onClick?: () => void; size?: ButtonProps["size"] }) {
    const { onClick, size } = props;
    const dispatch = useDispatch();
    return (
        <Button
            size={size}
            onClick={() => {
                dispatch(actionSetRightsDialog(true));
                if (onClick !== undefined) {
                    onClick();
                }
            }}
            color="bbyellow"
        >
            查看完整会员权益
        </Button>
    );
}
