import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { actionSetLoginHintDialog } from "../../../store/dialog/reducer";
import { selectLoginHintDialog } from "../../../store/dialog/selector";
import { useAppDispatch } from "../../../store/hooks";
import AnnounceDialog from "../../basic/feedbacks/dialog/AnnounceDialog";

export function LoginHintDialog() {
    const dispatch = useAppDispatch();
    const enable = useSelector(selectLoginHintDialog);
    return (
        <AnnounceDialog
            size="md"
            enable={enable}
            setEnable={(b: boolean) => {
                dispatch(actionSetLoginHintDialog(b));
            }}
            title="温馨提示"
            content={
                <Box sx={{ fontSize: "1.2rem", minHeight: "350px" }}>
                    <p>
                        推荐<Red>挂梯子</Red>, 使用<Red>chrome浏览器</Red>
                    </p>
                    <p>网站整体响应更快，模考体验更真实哦：</p>
                    <ol>
                        <li>模考创建更丝滑~</li>
                        <li>题目图片加载更迅速~</li>
                        <li>模考出分不用等~</li>
                        <li>数学符号展示更准确~</li>
                    </ol>
                    <p>
                        PS: 极个别的校园网会屏蔽梯子, 需要换成<Red>热点</Red>哦~~
                    </p>
                </Box>
            }
            onConfirm={() => {}}
        />
    );
}

function Red(props: PropsWithChildren<{}>) {
    const { children } = props;
    return <span style={{ color: "red", fontWeight: "bold" }}>{children}</span>;
}
