import { AlertError } from "../component/basic/feedbacks/snackbar/Snackbar";
import { TOKEN_KEY } from "../model/TokenAuthData";
import { actionSetShowOutOfLimitDialog, actionSetVIPDialog } from "../store/dialog/reducer";
import { actionSetBackdrop } from "../store/root/reducer";
import GetGlobalConfig from "../tools/global_config";
import { LogDebug, LogError } from "../tools/log";
import { RefreshPage } from "../tools/url";

const getBackendURL = (path: string): string => {
    return path;
};

interface requestBackendProps {
    uri: string;
    req_data: any;
    handleRsp: (rsp: any) => void;
    errTitle?: string;
    dispatch: any;
    backdrop?: boolean;
    afterErr?: () => void;
}

export async function requestBackend(props: requestBackendProps) {
    const { uri, req_data, handleRsp, dispatch, errTitle, backdrop, afterErr } = props;

    const token = localStorage.getItem(TOKEN_KEY);

    if (backdrop) {
        dispatch(actionSetBackdrop(true));
    }
    return fetch(getBackendURL(uri), {
        method: "post",
        headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            "Content-Type": "application/json",
            "API-Version": GetGlobalConfig().api_version,
        },
        body: JSON.stringify(req_data),
    })
        .then((rsp_raw) => {
            if (rsp_raw.ok) {
                return rsp_raw.json();
            }
            LogError("http error, rsp_raw:", rsp_raw);
            // http框架错误码
            if (rsp_raw.status === 401) {
                localStorage.removeItem(TOKEN_KEY);
                // new Promise((r) => setTimeout(r, 1000)).then(() => {
                //     window.location.href = "/login";
                // });
                throw Error("请登录");
            }
            if (rsp_raw.status === 500 || rsp_raw.status === 502) {
                throw Error(`${rsp_raw.status} ${rsp_raw.statusText}, 请稍后重试`);
            }
            throw Error(`${rsp_raw.status} ${rsp_raw.statusText}, 请检查网络`);
        })
        .then((rsp_json) => {
            // 后端错误码
            if (rsp_json["ok"] === true) {
                return handleRsp(rsp_json["data"]);
            }
            LogError(`backend error of url [${uri}], rsp_json:`, rsp_json);
            const errData = rsp_json["err_data"];
            if (errData["kind"] === "api_version_low") {
                new Promise((r) => setTimeout(r, 3 * 1000)).then(() => {
                    LogDebug("api version too low, refresh page");
                    RefreshPage();
                });
                throw Error("网页版本过低, 请刷新网页（3秒后自动刷新）");
            }
            // 触发频控
            if (errData["kind"] === "limit") {
                dispatch(actionSetVIPDialog(true));
                // dispatch(actionSetShowPermissionDialog({ show: true, isLimit: true }));
                throw Error("请激活权限");
            }
            // 体验额度用完
            if (errData["kind"] === "role_overflow") {
                dispatch(actionSetVIPDialog(true));
                throw Error("体验额度耗尽");
            }
            // 超过权益额度
            if (errData["kind"] === "day_overflow") {
                dispatch(
                    actionSetShowOutOfLimitDialog({
                        show: true,
                        subject: errData["overflow_subject"],
                        roleName: errData["role_name"],
                    })
                );
                throw Error("权益额度耗尽");
            }
            // 普通错误: 后台返回了文案
            if (errData["kind"].length > 0) {
                throw Error(errData["show"]);
            }
            // 普通错误: 无文案
            throw Error(errData["detail"]);
        })
        .catch((e) => {
            if (afterErr) {
                afterErr();
            }
            if (errTitle) {
                LogDebug(`dispatch error [${e}]`);
                const msg = `${errTitle}: ${e.message}`;
                AlertError(msg);
                return;
            }
            LogDebug(`fetch ${uri} fail:`, e);
        })
        .finally(() => {
            if (backdrop) {
                dispatch(actionSetBackdrop(false));
            }
        });
}
