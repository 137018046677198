import { Box, Button, Input, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { methodUploadQuestionImage } from "../../../../api/apiquestion/api";
import { actionSetUploadDialog } from "../../../../store/dialog/reducer";
import { selectUploadDialog } from "../../../../store/dialog/selector";
import { useAppDispatch } from "../../../../store/hooks";
import { selectQuestionID } from "../../../../store/question/selector";
import { selectEditMode } from "../../../../store/user/selector";
import { BBDialog } from "../../../basic/feedbacks/dialog/BBDialog";
import { AlertError, AlertSuccess } from "../../../basic/feedbacks/snackbar/Snackbar";
import { AdminBox } from "../../../basic/surfaces/box/AdminBox";

export function UploadDialog() {
    const dispatch = useAppDispatch();
    const open = useSelector(selectUploadDialog);
    const setOpen = (b: boolean) => {
        dispatch(actionSetUploadDialog(b));
    };
    return (
        <BBDialog
            confirmTitle="关闭"
            open={open}
            setOpen={setOpen}
            content={<ImageUpload />}
            noCancel
        />
    );
}

export function UploadButton() {
    const dispatch = useAppDispatch();
    const editMode = useSelector(selectEditMode);
    return (
        <>
            {editMode && (
                <AdminBox>
                    <Button
                        onClick={() => {
                            dispatch(actionSetUploadDialog(true));
                        }}
                    >
                        上传图片
                    </Button>
                </AdminBox>
            )}
        </>
    );
}

// 选择要上传的图片
export function ImagePick(props: { onUpload: (file: File) => Promise<void> }) {
    const { onUpload } = props;

    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const onFileUpload = async () => {
        setUploading(true);
        if (file === null) {
            AlertError("请选择图片");
            return;
        }
        if (file.name.length === 0) {
            AlertError("文件名为空");
            return;
        }
        onUpload(file).then(() => {
            setUploading(false);
        });
    };

    return (
        <>
            <Input type="file" onChange={onFileChange} />
            {/* <input type="file" onChange={onFileChange} /> */}
            <Button onClick={onFileUpload} disabled={file === null || uploading}>
                {uploading ? "上传中..." : "上传"}
            </Button>
        </>
    );
}

export function ImageUpload() {
    const dispatch = useAppDispatch();

    const questionID = useSelector(selectQuestionID);

    const [code, setCode] = useState("");
    return (
        <>
            <h4>题目: {questionID}</h4>
            <ImagePick
                onUpload={(file: File) => {
                    if (questionID.length === 0) {
                        AlertError("题目为空");
                        return new Promise<void>((resolve) => {
                            resolve();
                        });
                    }
                    return methodUploadQuestionImage(dispatch, {
                        file: file,
                        qid: questionID,
                    }).then((rsp) => {
                        setCode(rsp.img_html);
                        navigator.clipboard.writeText(rsp.img_html);
                        AlertSuccess("上传成功, 代码已复制到剪贴板");
                    });
                }}
            />
            <Box sx={{ height: "30px" }} />
            <hr />
            <TextField
                sx={{ width: "500px" }}
                value={code}
                placeholder="上传后复制此处代码到题目"
                multiline
            />
        </>
    );
}
