import { SvgIconProps } from "@mui/material";
import { XStack } from "../../../../basic/surfaces/box/XStack";

export interface RenderProps {
    title: any;
    value: string | any;
    Icon?: React.FC<SvgIconProps>;
}

export type Render = React.FC<RenderProps>;

export function RenderDisplay(props: { props: RenderProps; Render?: Render }) {
    const { props: rp, Render } = props;
    return Render !== undefined ? <Render {...rp} /> : <DefaultRender {...rp} />;
}

function DefaultRender(props: RenderProps) {
    const { title, value } = props;
    return (
        <>
            {typeof value === "string" ? (
                <p>
                    {title}：{value}
                </p>
            ) : (
                <XStack sx={{ marginBlockStart: "16px", marginBlockEnd: "16px" }}>
                    <span>{title}</span>
                    {value}
                </XStack>
            )}
        </>
    );
}
