import { LoginHintDialog } from "./component/business/dialog/LoginHintDialog";
import { OutOfLimitDialog } from "./component/business/dialog/OutOfLimitDialog";
import { VIPPayDialog } from "./component/business/dialog/PayDialog";
import PermissionMsgDialog from "./component/business/dialog/PermissionDialog";
import { RightsDialog } from "./component/business/dialog/RightsDialog";
import { SimulateNoPermissionDialog } from "./component/business/dialog/SimulateNoPermissionDialog";
import { SupportDialog } from "./component/business/dialog/SupportDialog";
import { VIPDialog } from "./component/business/dialog/VIPDialog";
import { CollectDialog } from "./component/business/question/detail/craft/collect/CollectTag";
import { UploadDialog } from "./component/business/question/detail/ImageUpload";
import { BuyCoinDialog } from "./page/flash/component/entrance/BuyCoinDialog";

export function Dialogs() {
    return (
        <>
            <PermissionMsgDialog />
            <VIPDialog />
            <OutOfLimitDialog />
            <VIPPayDialog />
            <RightsDialog />
            <LoginHintDialog />
            <BuyCoinDialog />
            <UploadDialog />
            <SimulateNoPermissionDialog />
            <SupportDialog />
            <CollectDialog />
        </>
    );
}
