export class UserDaily {
    exam: ExamDaily = new ExamDaily();
    exercise: ExerciseDaily = new ExerciseDaily();
    question: QuestionDaily = new QuestionDaily();
    signed: boolean = false;
    is_backend_data: boolean = false;
}

export class QuestionDaily {
    used: number = 0;
    used_issue: number = 0;
}

export class ExamDaily {
    used: number = 0;
}
export class ExerciseDaily {
    used_vquestions: number = 0;
    used_qquestions: number = 0;
}
