import { useRouteError } from "react-router-dom";
import { LogError } from "../tools/log";

export default function ErrorPage() {
    const error = useRouteError();
    LogError(error);
    LogError(typeof Error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{(error as Error)?.message || (error as { statusText?: string })?.statusText}</i>
            </p>
        </div>
    );
}
