import { apiUploadRequest } from "../api";
import { UploadImageReq, UploadImageRsp } from "./upload";

const apiUploadQuestionImage = "/api/question/upload_image";

export function methodUploadQuestionImage(dispatch: any, req: UploadImageReq) {
    const formData = new FormData();
    formData.append("file", req.file);
    formData.append("qid", req.qid);
    return apiUploadRequest<UploadImageRsp>({
        apiURL: apiUploadQuestionImage,
        req: formData,
        dispatch,
    });
}
